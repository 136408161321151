import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  useParams,
} from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

import "./styles/fontawesome-free-6.1.1-web/css/all.css";
import "./styles/_main.css";

import { routes, routesBase } from "./routes";

import Layout from "./pages/layout";
import Home from "./pages/home";
import Lost from "./pages/404";
import Updates from "./pages/updates";
import WhatIsCMV from "./pages/what-is-cmv";
import AboutTheStudy from "./pages/about-the-study";
import InfoForCenters from "./pages/information-for-centers";
import MeetTeam from "./pages/meet-team";
import ContactUs from "./pages/contact-us";
import Confirmation from "./pages/confirmation";
import faviconUrl from "./images/favicons/favicon.ico";
import favicon192Url from "./images/favicons/favicon-192.png";


import NewbornScreening from "./pages/blog/newborn-screening";
import CmvInNewborns from "./pages/blog/cmv-in-newborns";
import TakingAChance from "./pages/blog/taking-a-chance";
import CmvAwareness from "./pages/blog/cmv-awareness";
import TimeForACheckup from "./pages/blog/time-for-a-checkup";
import AwarenessAndIntervention from "./pages/blog/awareness-and-intervention";
import RememberingBabyLogan from "./pages/blog/remembering-baby-logan";
import AdvocacyAndAwareness from "./pages/blog/advocacy-and-awareness";
import PreventingCmv from "./pages/blog/preventing-cmv";
import CheckUp from "./pages/blog/check-up";
import CollaborationIsKey from "./pages/blog/collaboration-is-key";



import MyDataHelps from "@careevolution/mydatahelps-js"
import TagManager from "react-gtm-module";

import ScrollToTop from "./helpers/ScrollToTop";

if (process.env.REACT_APP_BUILD_ENV === "development") {
    MyDataHelps.setParticipantAccessToken({}, "https://mdhorg.ce.dev/");
  }

if (process.env.REACT_APP_BUILD_ENV === "production") {
  const tagManagerArgs = {
    gtmId: "GTM-KG8DCS4",
  };
  TagManager.initialize(tagManagerArgs);
}

const rtlLanguages = ["ar", "fa", "ur", "ps"];

export default function App() {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    AOS.init({
      once: true,
      disable: "phone",
      duration: 700,
      delay: 250,
      easing: "ease-out-cubic",
    });
    const injectMeta = () => {
      const faviconElement = document.getElementById("favicon");
      const favicon192Element = document.getElementById("favicon192");
      const metaDescription = document.getElementById("description");
      const hreflangElement = document.getElementById("hreflang");
      hreflangElement.href = window.location.origin + window.location.pathname;
      hreflangElement.hreflang = i18n.resolvedLanguage;
      faviconElement.href = faviconUrl;
      // favicon192Element.href = favicon192Url;
      metaDescription.content = t("site-description");
      document.documentElement.dir = rtlLanguages.includes(
        i18n.resolvedLanguage
      )
        ? "rtl"
        : "ltr";
    };
    injectMeta();
  });

  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Layout />}>

          <Route path={routes.root} element={<Home />} />
          <Route path={routesBase.root} element={<Home />} />
          <Route path={routes.home} element={<Home />} />
          <Route path={routesBase.home} element={<Home />} />
          <Route path={routes.updates} element={<Updates />} />
          <Route path={routesBase.updates} element={<Updates />} />
          <Route path={routes.whatIsCmv} element={<WhatIsCMV />} />
          <Route path={routesBase.whatIsCmv} element={<WhatIsCMV />} />
          <Route path={routes.aboutTheStudy} element={<AboutTheStudy />} />
          <Route path={routesBase.aboutTheStudy} element={<AboutTheStudy />} />
          <Route path={routes.infoForCenters} element={<InfoForCenters />} />
          <Route path={routesBase.infoForCenters} element={<InfoForCenters />} />
          <Route path={routes.confirmation} element={<Confirmation />} />
          <Route path={routesBase.confirmation} element={<Confirmation />} />
          <Route path={routes.newbornScreening} element={<NewbornScreening />} />
          <Route path={routesBase.newbornScreening} element={<NewbornScreening />} />
          <Route path={routes.takingAChance} element={<TakingAChance />} />
          <Route path={routesBase.takingAChance} element={<TakingAChance />} />
          <Route path={routes.cmvInNewborns} element={<CmvInNewborns />} />
          <Route path={routesBase.cmvInNewborns} element={<CmvInNewborns />} />
          <Route path={routes.cmvAwareness} element={<CmvAwareness />} />
          <Route path={routesBase.cmvAwareness} element={<CmvAwareness />} />
          <Route path={routes.timeForACheckup} element={<TimeForACheckup />} />
          <Route path={routesBase.timeForACheckup} element={<TimeForACheckup />} />
          <Route path={routes.awarenessAndIntervention} element={<AwarenessAndIntervention />} />
          <Route path={routesBase.awarenessAndIntervention} element={<AwarenessAndIntervention />} />
          <Route path={routes.rememberingBabyLogan} element={<RememberingBabyLogan />} />
          <Route path={routesBase.rememberingBabyLogan} element={<RememberingBabyLogan />} />
          <Route path={routes.advocacyAndAwareness} element={<AdvocacyAndAwareness />} />
          <Route path={routesBase.advocacyAndAwareness} element={<AdvocacyAndAwareness />} />
          <Route path={routes.preventingCmv} element={<PreventingCmv />} />
          <Route path={routesBase.preventingCmv} element={<PreventingCmv />} />
          <Route path={routes.collaborationIsKey} element={<CollaborationIsKey />} />
          <Route path={routesBase.collaborationIsKey} element={<CollaborationIsKey />} />
          <Route path={routes.checkUp} element={<CheckUp />} />
          <Route path={routesBase.checkUp} element={<CheckUp />} />
          <Route path={routes.meetTeam} element={<MeetTeam />} />
          <Route path={routesBase.meetTeam} element={<MeetTeam />} />
          <Route path={routes.contactUs} element={<ContactUs />} />
          <Route path={routesBase.contactUs} element={<ContactUs />} />
          <Route path="" element={<Lost />} />
          <Route path="*" element={<Lost />} />
        </Route>
      </Routes>
    </Router>
  );
}

import React from "react";
import {
	useSearchParams, Link, generatePath
} from "react-router-dom";
import { routes } from "../routes";
import { useTranslation } from "react-i18next";
import blog01 from "../images/kids-sharing.jpg";
import blog02 from "../images/kids-with-chairs.jpg";
import blog03 from "../images/kid-with-legos.jpg";
import blog04 from "../images/three-kids-toys.jpg";
import blog05 from "../images/infant-crawling.jpg";
import blog06 from "../images/infants-reading.jpg";
import blog07 from "../images/holding-hands.jpg";
import blog08 from "../images/conference-speaker.jpg";
import blog09 from "../images/scientist.jpg";
import blog10 from "../images/kissing-forehead.jpg";
import blog11 from "../images/doctors-talking.jpg"



export default function BlogThumbnails() {
    const { t,i18n } = useTranslation();
  return (
    <div className="news blog">
        <div className="card-wrap">
            <Link 
            to={generatePath(routes.cmvInNewborns, { locale: i18n.resolvedLanguage })}
            className="card">
                <div style={{backgroundImage: "url(" + blog01 + ")"}} class="img-container"></div>
                <span className="details">
                    <span>CMV in Newborns</span>
                    {/* <span>March 21, 2022</span> */}
                </span>
            </Link>
        </div>
        <div className="card-wrap">
        <Link 
            to={generatePath(routes.newbornScreening, { locale: i18n.resolvedLanguage })}
            className="card">
                <div style={{backgroundImage: "url(" + blog02 + ")"}} class="img-container"></div>
                <span className="details">
                    <span>Newborn Screening</span>
                    {/* <span>Feb 21, 2023</span> */}
                </span>
            </Link>
        </div>        
        <div className="card-wrap">
        <Link 
            to={generatePath(routes.takingAChance, { locale: i18n.resolvedLanguage })}
            className="card">
                <div style={{backgroundImage: "url(" + blog03 + ")"}} class="img-container"></div>
                <span className="details">
                    <span>Taking a Chance</span>
                    {/*  <span>March 21, 2022</span> */}
                </span>
            </Link>
        </div> 
        <div className="card-wrap">
        <Link 
            to={generatePath(routes.cmvAwareness, { locale: i18n.resolvedLanguage })}
            className="card">
                <div style={{backgroundImage: "url(" + blog04 + ")"}} class="img-container"></div>
                <span className="details">
                    <span><b>CMV Awareness Month:</b><br/>Spreading The Word, Not The Virus</span>
                    {/*  <span>March 21, 2022</span> */}
                </span>
            </Link>
        </div>
        <div className="card-wrap">
        <Link 
            to={generatePath(routes.timeForACheckup, { locale: i18n.resolvedLanguage })}
            className="card">
                <div style={{backgroundImage: "url(" + blog05 + ")"}} class="img-container"></div>
                <span className="details">
                    <span>Time for a Check-Up!</span>
                    {/*  <span>March 21, 2022</span> */}
                </span>
            </Link>
        </div>
        <div className="card-wrap">
        <Link 
            to={generatePath(routes.awarenessAndIntervention, { locale: i18n.resolvedLanguage })}
            className="card">
                <div style={{backgroundImage: "url(" + blog06 + ")"}} class="img-container"></div>
                <span className="details">
                    <span>Awareness and Early Intervention</span>
                    {/*  <span>March 21, 2022</span> */}
                </span>
            </Link>
        </div>
        <div className="card-wrap">
        <Link 
            to={generatePath(routes.rememberingBabyLogan, { locale: i18n.resolvedLanguage })}
            className="card">
                <div style={{backgroundImage: "url(" + blog07 + ")"}} class="img-container"></div>
                <span className="details">
                    <span>Remembering Baby Logan</span>
                    {/*  <span>March 21, 2022</span> */}
                </span>
            </Link>
        </div>
        <div className="card-wrap">
        <Link 
            to={generatePath(routes.advocacyAndAwareness, { locale: i18n.resolvedLanguage })}
            className="card">
                <div style={{backgroundImage: "url(" + blog08 + ")"}} class="img-container"></div>
                <span className="details">
                    <span>2023 CMV Public Health and Policy Conference</span>
                    {/*  <span>March 21, 2022</span> */}
                </span>
            </Link>
        </div>
        <div className="card-wrap">
        <Link 
            to={generatePath(routes.checkUp, { locale: i18n.resolvedLanguage })}
            className="card">
                <div style={{backgroundImage: "url(" + blog09 + ")"}} class="img-container"></div>
                <span className="details">
                    <span>Check-up!</span>
                    {/*  <span>March 21, 2022</span> */}
                </span>
            </Link>
        </div>
        <div className="card-wrap">
        <Link 
            to={generatePath(routes.preventingCmv, { locale: i18n.resolvedLanguage })}
            className="card">
                <div style={{backgroundImage: "url(" + blog10 + ")"}} class="img-container"></div>
                <span className="details">
                    <span>Preventing CMV Infection</span>
                    {/*  <span>March 21, 2022</span> */}
                </span>
            </Link>
        </div>
        <div className="card-wrap">
        <Link 
            to={generatePath(routes.collaborationIsKey, { locale: i18n.resolvedLanguage })}
            className="card">
                <div style={{backgroundImage: "url(" + blog11 + ")"}} class="img-container"></div>
                <span className="details">
                    <span>Collaboration Is Key</span>
                    {/*  <span>March 21, 2022</span> */}
                </span>
            </Link>
        </div>
    </div>
  )
}
